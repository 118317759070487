@import "../../style/partials/mixins";
@import "../../style/partials/variables";

.purchase-team {
  background-color: $light-background;
  padding: calc($base-padding * 2);
  border-radius: $base-border-radius;
  display: flex;
  align-items: center;
  gap: calc($base-padding * 2);
  box-shadow: 4.52962px 4.52962px 9.05923px rgb(0 0 0 / 5%);

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc($base-padding * 2);
    margin: auto 0;
    align-self: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px 50px 20px;
    height: 375px;

    @include tablet {
      padding: 30px 50px 50px 50px;
      height: 600px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
      align-items: center;
    }

    &-text {
      margin: 0;
      font-weight: bold;
    }

    &-help {
      margin: 0;
      color: $accent;
      font-size: 15px;
    }

    &-helper {
      margin: 0;
      font-size: 32px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: calc($base-padding * 2);
    width: 100%;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    gap: calc($base-padding / 2);

    &-title {
      margin: 0;
    }

    &-description {
      margin: 0;
      color: $gray;
    }
  }

  &__form {
    display: flex;
    gap: calc($base-padding * 2);
    flex-direction: column;

    &-personal {
      display: flex;
      gap: $base-padding;
      flex-direction: column;
    }

    &-payment {
      display: flex;
      gap: $base-padding;
      flex-direction: column;
    }

    &-inline {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
      align-items: center;

      @include tablet {
        flex-direction: row;
      }
    }
  }

  &__show-password {
    margin-top: 10px;
    margin-left: $base-padding;
    display: flex;
    gap: calc($base-padding / 2);
    align-items: center;
    font-size: 13px;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $accent2;
    }
  }

  &__form-reminder {
    font-size: 15px;
    color: grey;
    align-self: center;
  }

  &__team {
    display: flex;
    flex-direction: column;
    gap: $base-padding;

    &-selector {
      display: flex;
      justify-content: space-between;
      gap: calc($base-padding * 2);
      align-items: center;
    }

    &-select {
      flex-grow: 1;
    }
  }
}

.StripeElement {
  flex-grow: 1;
  padding: $base-padding calc($base-padding * 1.5);
  background-color: #eef0ff;
  border-radius: $base-border-radius;
  border: 1px solid #eef0ff;
  font-family: "Work Sans", sans-serif;
  font-size: 15px !important;
  width: 100%;

  @include tablet {
    width: auto;
  }
}

.thank-you {
  background-color: $light-background;
  padding: calc($base-padding * 2);
  border-radius: $base-border-radius;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: calc($base-padding * 1.5);
  overflow: hidden;
  box-shadow: 4.52962px 4.52962px 9.05923px rgb(0 0 0 / 5%);
  text-align: center;

  &__check {
    font-size: 40px;
    color: #8ed98e;
    margin: 0;
  }

  &__reminder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc($base-padding / 2);
  }

  &__title {
    margin: 0;
  }

  &__description {
    margin: 0;
    color: $gray;
  }

  &__reciept {
    display: flex;
    flex-direction: column;
    gap: $base-padding;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: $base-padding calc($base-padding * 3);
      background-color: white;
      box-shadow: 0px 0px 10px 0px #e7e7e7;
      border-radius: $base-border-radius;
      flex-direction: column;

      @include desktop {
        flex-direction: row;
      }

      @include desktop {
        width: 800px;
      }

      &-title {
        font-weight: 600;
        margin: 0;
        font-size: 16px;

        @include tablet {
          font-size: 20px;
        }
      }

      &-price {
        color: #7dbe7d;
        font-weight: 600;
        margin: 0;
      }
    }

    &__total {
      display: flex;
      justify-content: flex-end;
      gap: calc($base-padding * 2);
      padding: 0 calc($base-padding * 3);

      @include desktop {
        width: 800px;
      }

      &-price {
        font-weight: bold;
      }
    }
  }

  &__game {
    display: flex;
    justify-content: space-between;
    gap: $base-padding;
    align-items: center;
    flex-direction: column;

    &-data {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
    }

    &-code {
      margin: 0;

      &-id {
        color: $accent2;
        font-weight: bold;
      }
    }

    &-link {
      padding: calc($base-padding / 1.8) $base-padding;
      border: 2px dashed $accent2;
      border-radius: $base-border-radius;
      cursor: pointer;
      word-break: break-word;

      &-text {
        margin: 0;
        font-size: 18px;
      }

      &-info {
        margin: 0;
        font-size: 13px;
      }
    }
  }

  &__timer-warning {
    margin-top: 50px;
    color: rgb(250, 129, 129);
    font-weight: 600;
  }

  &__downloads {
    display: flex;
    gap: $base-padding;

    &-image {
      height: 35px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: calc($base-padding / 2);

    &-contact {
      margin: 0;
    }

    &-copyright {
      margin: 0;
      font-size: 15px;
    }
  }
}
