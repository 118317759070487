@import "variables";

/* =================== Responsive Breakpoints Handler */

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-home {
  @media (min-width: 1380px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

/* =================== Reusable Components */

@mixin page-wrapper {
  padding: ($base-padding * 2) ($base-padding * 2.5);

  @include tablet {
    padding: ($base-padding * 2);
  }

  @include desktop {
    width: $desktop !important;
    margin: 0 auto;
  }
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: $base-border-radius;

    &:hover {
      cursor: pointer;
      background: $accent2;
    }
  }
}
