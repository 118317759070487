@import "../../../style/partials/mixins";
@import "../../../style/partials/variables";

.suspect-image {
  display: flex;
  flex-direction: column;
  gap: calc($base-padding * 2);

  @include tablet {
    flex-direction: row;
  }

  &-data {
    width: 430px;
    height: 440px;
    zoom: 80%;

    @include tablet {
      width: 430px;
      zoom: 80%;
    }

    @include desktop {
      width: 50%;
      zoom: 100%;
    }
  }

  &-uploader {
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }
}

.suspect-component {
  display: flex;
  flex-direction: column;
  gap: $base-padding;

  &__motive {
    resize: none;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #eef0ff;
    border-radius: $base-border-radius;
    border: 1px solid #eef0ff;
    font-size: 15px;
    padding: calc($base-padding * 1.5) calc($base-padding * 2);

    &::placeholder {
      color: #8384a3;
    }

    &:focus {
      border: 1px solid $accent2;
      outline: none;
    }

    &:active {
      border: 1px solid $accent2;
      outline: none;
    }
  }

  &__uploader {
    border: 2px dashed $dark-background;
    border-radius: $base-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    color: $dark-background;
    cursor: pointer;
    flex-grow: 1;
    gap: 8px;
    padding: calc($base-padding * 2);

    &-title {
      margin: 0;
      font-size: 16px;
    }
  }
}
