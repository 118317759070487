@import "../../style/partials/mixins";
@import "../../style/partials/variables";

.testimonail {
  display: flex;
  gap: calc($base-padding * 2);

  transform: scale(1) !important;

  &__image-container {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: calc($base-padding / 2);

    &-title {
      margin: 0;
      width: 230px;
    }

    &-user {
      margin: 0;
      font-size: 15px;
      color: $light-background;
    }

    &-ratings {
      display: flex;
      gap: 2px;
      color: $accent2;
    }
  }
}
