@import "../../style/partials/mixins";

.new-game {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    &__wrapper {
        width: 100%;
        @include page-wrapper;
    }
}