@import "./variables";
@import "./typography";
@import "./mixins";
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Work+Sans:wght@300;400;600;700&display=swap");

* {
  box-sizing: border-box;

  &::-moz-selection {
    color: $white;
    background: $accent2;
  }

  &::selection {
    color: $white;
    background: $accent2;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: url("../../assets/images/background_pattern.png");
  background-size: 5%;

  @include custom-scroll;
}

.mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  font-weight: bold;

  &--type-1 {
    background-image: linear-gradient(
      to right,
      rgba(223, 32, 204, 0.1),
      rgba(223, 32, 204, 0.7) 4%,
      rgba(223, 32, 204, 0.3)
    );
  }

  &--type-2 {
    background-image: linear-gradient(
      to right,
      rgba(29, 187, 223, 0.1),
      rgba(29, 187, 223, 0.7) 4%,
      rgba(29, 187, 223, 0.3)
    );
  }

  &--type-3 {
    background-image: linear-gradient(
      to right,
      rgba(33, 222, 147, 0.1),
      rgba(33, 222, 147, 0.7) 4%,
      rgba(33, 222, 147, 0.3)
    );
  }

  &--type-4 {
    background-image: linear-gradient(
      to right,
      rgba(255, 150, 27, 0.1),
      rgba(255, 150, 27, 0.7) 4%,
      rgba(255, 150, 27, 0.3)
    );
  }

  &--type-5 {
    background-image: linear-gradient(
      to right,
      rgba(224, 92, 92, 0.1),
      rgba(224, 92, 92, 0.7) 4%,
      rgba(224, 92, 92, 0.3)
    );
  }
}

li:not(:last-child) {
  margin-bottom: 8px;
  line-height: 22px;
}

a {
  text-decoration: none;
  color: $accent2;
  font-weight: 600;
}

.Toastify__toast {
  border-radius: $base-border-radius !important;
  padding: $base-padding !important;
  font-size: 15px;
}

.Toastify__toast-body {
  display: flex !important;
  gap: calc($base-padding / 2) !important;
  align-items: center !important;
}

.example {
  &__title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  &__sub-title {
    font-size: 15px;
  }

  &__body {
    font-size: 15px;
    display: flex;
    gap: $base-padding;
    flex-direction: column;
    align-items: flex-start;
  }

  &__type {
    background-color: $accent2;
    padding: 5px 15px;
    color: white;
    border-radius: $base-border-radius;

    &--2 {
      background-color: $accent;
    }
  }

  &__image-container {
    width: 100%;
    height: 450px;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url("../../assets/images/board.jpg");
    background-color: rgb(241, 241, 241);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $base-padding;
    border-radius: $base-border-radius;

    &-photo {
      position: relative;
    }

    &-photo-1 {
      position: absolute;
      width: 300px;
      height: 350px;
      background-color: orange;
      display: inline-block;
      transform: rotate(5deg);
      border: 8px solid white;
      box-shadow: -8px 5px 16px 1px rgba(49, 49, 49, 0.56);

      &--type-1 {
        background-color: $primary;
      }

      &--type-2 {
        background-color: $accent;
      }

      &--type-3 {
        background-color: $accent2;
      }

      &--type-4 {
        background-color: $accent3;
      }

      &--type-5 {
        background-color: rgb(224, 92, 92);
      }
    }

    &-photo-2 {
      width: 300px;
      height: 350px;
      background-color: orange;
      display: inline-block;
      transform: rotate(-5deg);
      border: 8px solid white;
      box-shadow: -8px 5px 16px 1px rgba(49, 49, 49, 0.56);
      position: relative;
      padding: 5px;

      &--type-1 {
        background-color: $primary;
      }

      &--type-2 {
        background-color: $accent;
      }

      &--type-3 {
        background-color: $accent2;
      }

      &--type-4 {
        background-color: $accent3;
      }

      &--type-5 {
        background-color: rgb(224, 92, 92);
      }
    }

    &-tape {
      background: linear-gradient(
          rgba(255, 255, 235, 0.3),
          rgba(255, 255, 235, 0.3)
        ),
        url("../../assets/images/paper.gif");
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      content: "";
      display: block;
      height: 45px;
      position: absolute;
      margin: auto;
      width: 250px;
      right: -70px;
      bottom: 20px;
      transform: rotate(-35deg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Permanent Marker", cursive;
      color: #585050;
      font-size: 24px;

      &--type-1 {
        transform: rotate(-35deg);
        right: -70px;
        bottom: 20px;
      }

      &--type-2 {
        transform: rotate(40deg);
        left: -250px;
        bottom: 30px;
      }

      &--type-3 {
        transform: rotate(-35deg);
        right: -70px;
        bottom: 20px;
      }

      &--type-4 {
        transform: rotate(-40deg);
        left: -250px;
        top: -220px;
      }

      &--type-5 {
        transform: rotate(-15deg);
        right: -40px;
        bottom: 0px;
      }
    }

    &-pin-container {
      position: absolute;
      width: 55px;
      height: 50px;
      top: 10px;
      right: 10px;

      &--type-1 {
        top: 10px;
        right: 10px;
      }

      &--type-2 {
        top: 5px;
        right: 80px;
        filter: hue-rotate(300deg);
      }

      &--type-3 {
        top: 25px;
        right: 25px;
        filter: hue-rotate(110deg);
      }

      &--type-4 {
        top: 20px;
        right: 10px;
      }

      &--type-5 {
        top: -10px;
        left: 20px;
      }
    }

    &-pin {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-crop-modal {
  width: 100%;
  height: 100%;
  position: relative;
}
