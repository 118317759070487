@import "../../style/partials/variables";

.button {
    border: none;
    border-radius: $base-border-radius;
    padding: calc($base-padding / 1.2) calc($base-padding * 6) calc($base-padding / 1.2) calc($base-padding * 3);
    color: $white;
    position: relative;
    overflow: hidden;

    &--left {
        padding: calc($base-padding / 1.2) calc($base-padding * 3) calc($base-padding / 1.2) calc($base-padding * 6);
    }

    &:hover {
        cursor: pointer;
        background-color: #dfe3fb;
        color: $dark-background;
    }

    &:hover .button-icon {
        cursor: pointer;
    }

    &--primary {
        background-color: $dark-background;
    }

    &--secondary {
        background-color: $secondary;
    }

    &-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background-color: $accent2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        color: $dark-background;
    }

    &-icon-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        background-color: $accent2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        color: $dark-background;
    }
}