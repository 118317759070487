@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.intro-form {
    display: flex;
    flex-direction: column;
    gap: calc($base-padding * 4);

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__logo-container {
        width: 250px;
        height: 250px;
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__intro {
        display: flex;
        flex-direction: column;
        gap: $base-padding;
        align-items: center;

        &-title {
            margin: 0;
        }

        &-description {
            margin: 0;
            font-size: 22px;
            font-weight: 600;
        }
    }

    &__input {
        display: flex;
        flex-direction: column;
        gap: $base-padding;

        &-help {
            margin: 0;
        }
    }
}