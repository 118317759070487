@import "../../style/partials/variables";

.input {
  padding: $base-padding calc($base-padding * 1.5);
  background-color: #eef0ff;
  border-radius: $base-border-radius !important;
  border: 1px solid #eef0ff;
  flex-grow: 1;
  position: relative;

  &-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
  }

  &::placeholder {
    color: #8384a3;
  }

  &:focus {
    border: 1px solid $accent2;
    outline: none;
  }

  &:active {
    border: 1px solid $accent2;
    outline: none;
  }

  &__lable {
    color: $gray;
    font-size: 14px;
  }

  &__clear {
    position: absolute;
    top: 15px;
    right: 20px;
    color: $gray;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }
}
