/* =================== Colors */

// Colors
$black: #222222;
$white: #f8f8f8;
$accent: #F0EFFF;
$accent2: #A7A3FF;
$primary: #725095;
$secondary: #3a2d47;
$gray: #BBBBBB;
$lightgray: #dadada;

/* =================== Others */

$desktop: 1000px;
$tablet: 768px;

$base-padding: 16px;
$desktop-padding: 32px;

$base-border-radius: 5px;

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

/* =================== Reusable Components */

@mixin page-wrapper {
    padding: ($base-padding * 2) ($base-padding * 2.5);

    @include tablet {
        padding: ($base-padding * 2);
    }

    @include desktop {
        width: $desktop !important;
        margin: 0 auto;
    }
}


.thank-youx {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        width: 100%;
        @include page-wrapper;

        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
        align-items: center;
        justify-content: center;
    }

    &__check {
        font-size: 40px;
        color: #8ed98e;
        margin: 0;
    }

    &__devider {
        border: 0;
        clear:both;
        display:block;
        width: 30%;               
        background-color:$gray;
        height: 1px;
    }

    &__reminder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: calc($base-padding / 2);
    }

    &__title {
        margin: 0;
    }

    &__description {
        margin: 0;
        color: $gray;
    }

    &__reciept {
        display: flex;
        flex-direction: column;
        gap: $base-padding;

        &-header {
            display: flex;
            justify-content: space-between;
            width: 800px;
            padding: $base-padding calc($base-padding * 2);
            box-shadow: 0px 0px 10px 0px #e7e7e7;
            border-radius: $base-border-radius;

            &-title {
                font-weight: 600;
            }

            &-price {
                color: #7dbe7d;
                font-weight: 600;
            }
        }

        &__total {
            display: flex;
            justify-content: flex-end;
            gap: calc($base-padding * 4);
            padding: 0 calc($base-padding * 2);

            &-price {
                font-weight: bold;
            }
        }
    }

    &__game {
        width: 800px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-data {
            display: flex;
            flex-direction: column;
            gap: $base-padding;
        }

        &-code {
            margin: 0;
        }

        &-link {
            padding: calc($base-padding / 2) $base-padding;
            border: 2px dashed $accent2;
            border-radius: $base-border-radius;
            cursor: pointer;

            &-text {
                margin: 0;
            }

            &-info {
                margin: 0;
                font-size: 13px;
            }
        }
    }
}