@import "../../style/partials/mixins";
@import "../../style/partials/variables";

.welcome {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light-background;
  flex-direction: column;

  &__banner {
    &-wrapper {
      padding: ($base-padding * 2);
      display: flex;
      flex-direction: column;
      gap: $base-padding;

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        padding: 100px 0;
        width: 1380px !important;
        margin: 0 auto;
        flex-direction: row;
      }
    }

    &-title {
      font-size: 25px;
      margin: 0;
    }

    &-description {
      margin: 0;
    }

    &-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: calc($base-padding * 2);
      justify-content: center;

      @include desktop-home {
        width: 40%;
        padding: 0 calc($base-padding * 2);
      }
    }

    &-right {
      width: 100%;

      @include desktop-home {
        width: 60%;
      }
    }

    &-image-container {
      width: 100%;
      border-radius: $base-border-radius;
      overflow: hidden;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-logo-container {
      width: 180px;
      height: 180px;
    }

    &-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__testimonials {
    background-image: url("../../assets/images/background_pattern.png");
    background-size: 100px;
    background-color: $dark-background;
    color: white;

    &-wrapper {
      display: flex;
      gap: 45px;
      padding: calc($base-padding * 2);
      flex-direction: column;

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        width: 1380px !important;
        padding: 100px ($base-padding * 2.5);
        margin: 0 auto;
        flex-direction: row;
        gap: 65px;
      }
    }

    &-list {
      display: none;
      flex-direction: column;
      gap: calc($base-padding * 4);
      flex-grow: 1;

      @include tablet {
        display: none;
      }

      @include desktop-home {
        display: flex;
      }

      &-group {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: calc($base-padding * 2);

        @include desktop-home {
          flex-direction: row;
        }
      }
    }

    &-list-mobile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: calc($base-padding * 4);
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      @include desktop-home {
        display: none;
      }
    }

    &-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $base-padding;

      &-title {
        margin: 0;
      }

      &-description {
        font-size: 15px;
        margin: 0;
      }
    }
  }

  &__how-works {
    &-wrapper {
      padding: calc($base-padding * 2);

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        width: 1380px !important;
        padding: 100px ($base-padding * 2.5);
        margin: 0 auto;
      }
    }

    &-slider {
      display: none;

      @include tablet {
        display: block;
      }
    }

    &-swiper {
      height: 500px;

      @include tablet {
        height: auto;
      }
    }

    &-slide {
      width: 100%;
      height: 500px;
      display: flex;
      gap: calc($base-padding * 2);
      justify-content: space-between;
      align-items: center;
      padding: 100px;

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include tablet {
          width: 50%;
        }

        @include desktop-home {
          width: 600px;
          height: 350px;
        }
      }

      &-image {
        @include tablet {
          width: 30%;
        }
      }

      &-image-mobile {
        width: 100%;
      }

      &-image-data-mobile {
        width: 100%;
      }
    }

    &-mobile {
      display: flex;
      flex-direction: column;
      gap: calc($base-padding * 2);

      &-section {
        display: flex;
        flex-direction: column;
        gap: $base-padding;
      }

      @include tablet {
        display: none;
      }
    }
  }

  &__faq {
    background-image: url("../../assets/images/background_pattern.png");
    background-size: 100px;
    background-color: $dark-background;
    color: $dark-background;

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
      padding: calc($base-padding * 2);

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        width: 1380px !important;
        padding: 100px ($base-padding * 2.5);
        margin: 0 auto;
      }
    }

    &-title {
      color: white;
    }

    &-helper {
      color: white;
    }
  }

  &__logos {
    background-color: $light-background;
    text-align: center;

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
      padding: calc($base-padding * 2);

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        width: 1380px !important;
        padding: 100px ($base-padding * 2.5);
        margin: 0 auto;
      }
    }

    &-title {
      margin-bottom: 20px;
    }

    &-list {
      display: flex;
      gap: $base-padding;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-logo {
      border: 2px solid $accent;
      width: 150px;
      height: 90px;
      border-radius: calc($base-border-radius / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;

      &-image {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__footer {
    background-color: white;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: calc($base-padding * 2);
      gap: $base-padding;

      @include tablet {
        padding: ($base-padding * 2);
      }

      @include desktop-home {
        width: 1380px !important;
        padding: 30px ($base-padding * 2.5);
        margin: 0 auto;
      }
    }

    &-logo-container {
      height: 50px;
    }

    &-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-copyright {
      white-space: nowrap;
    }
  }
}
