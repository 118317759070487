@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;700&display=swap');

body {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
}

p {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
}

a {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
}

h1 {
    font-size: 80px;
    color: $headings;
}

h2 {
    font-size: 40px;
    color: $headings;
}

h3 {
    font-size: 24px;
    color: $headings;
}

input {
    font-family: 'Work Sans', sans-serif;
    font-size: 15px !important;
}

textarea  
{  
    font-family: 'Work Sans', sans-serif;
    font-size: 15px !important;
}

.button {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
}