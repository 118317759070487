@import "../../../style/partials/variables";

.text-area-container {
  position: relative;

  &__clear {
    position: absolute;
    top: 15px;
    right: 30px;
    color: $gray;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }
}
