@import "../../style/partials/mixins";
@import "../../style/partials/variables";

.form-steps {
    display: flex;
    flex-direction: column;
    gap: calc($base-padding * 2);

    &__controller {
        &-wrapper {
            display: flex;
            justify-content: space-between;
        }

        &-button {
            display: flex;
            gap: $base-padding;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: $accent2;
            }

            &:hover .form-steps__controller-button-icon {
                color: $white;
                transform: scale(1.1);
                background-color: $accent2;
            }

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $headings;
                border-radius: 50px;
                height: 40px;
                width: 40px;
                background-color: $accent2;

                &--shadow {
                    background-color: $gray;
                }
            }
        }
    }

    &__counter {
        margin: 0;
    }
}