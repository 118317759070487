/* =================== Colors */

// Colors
$headings: #313131;
$white: #f8f8f8;

$primary: #DF20CC;
$secondary: #ffc2f9;
$accent: #1DBBDF;
$accent2: #21DE93;
$accent3: #FF961B;

$gray: #BBBBBB;
$lightgray: #dadada;
$light-background: #F8F9FF;
$dark-background: #1C1356;

/* =================== Others */

$desktop: 1000px;
$tablet: 768px;

$base-padding: 16px;
$desktop-padding: 32px;

$base-border-radius: 35px;